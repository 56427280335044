import './Catering.css'
import Pdf from "../../images/CateringLink.pdf";
import EmailForm from '../homepage/EmailForm';
import { AiFillPhone } from "react-icons/ai";

const Catering = () => {
    return <div className='cateringContainer'>
        <div className='cateringInfoSection'>
            <img className='cateringImg' src={`${require("../../images/cateringimg.png")}`} />
            <div className='cateringPricingInfo'>
                <h1 className='cateringInfoTxt cateringInfoHeader'> Catering </h1>
                <p className='cateringInfoTxt'> Want to enjoy some of your favorite Three Seasons
                dishes at family or work functions? After many requests, we're happy to finally <br/>
                offer catering!
                 </p>
                <p className='cateringInfoTxt'>
                    Our catering options include some of our all time favorites
                    and come in generous portions that serve 25-50 depending on <br/>
                    your needs.
                 </p>
                <div className='cateringBtnContainer'>
                    <div className='cateringBtnWrapper'>
                        <a href={Pdf} className='cateringBtnLink'> More Info </a> 
                    </div>
                </div>
            </div>
        </div>
        <div className='cateringInfoSection subInfoSection'>
            <div className='cateringSubSection'>
            <div className='cateringContactHeader'>
              <h1 style={{ marginBottom: '0', marginTop: '10px' }}> Contact Us </h1>
              <div style={{marginRight: '20px'}}>
              <AiFillPhone className="InfoIcon" />{" "}
              <a href="tel:8606445077">860-644-5077</a>
              </div>
            </div>
            <EmailForm />
            </div>
            <img className='cateringImg' src={`${require("../../images/cateringimg5.png")}`} />
        </div>
    </div>
}

export default Catering