import './gallery.css'
import { useState } from 'react';
import { AiOutlineArrowRight, AiOutlineArrowLeft, AiOutlineClose  } from "react-icons/ai";

const Gallery = ({images}) => {
    const [slideNumber, setSlideNumber] = useState(0)
    const [openModal, setOpenModal] = useState(false)

    const handleOpenModal = (index) => {
        setSlideNumber(index)
        setOpenModal(true)
    }

    const prevSlide = () => {
        slideNumber === 0 ? 
        setSlideNumber(images.length - 1) : 
        setSlideNumber(slideNumber - 1)
    }

    const nextSlide = () => {
        slideNumber === images.length - 1 ? 
        setSlideNumber(0) : 
        setSlideNumber(slideNumber + 1)
    }

    return (
        <div>
            {openModal && 
                <div className='sliderWrapper'> 
                    <AiOutlineClose className='btnClose' onClick={() => setOpenModal(false)} />
                    <AiOutlineArrowLeft className='btnPrev' onClick={prevSlide} />
                    <AiOutlineArrowRight className='btnNext' onClick={nextSlide} />
                    <div className='fullScreenImage'> 
                        <img src={images[slideNumber].img} alt='' width='600px' height='800px' />
                    </div>
                </div>
            }
            <div className='galleryWrapper'>
                {images.map((slide, index) => {
                    return <div className='single' key={index} onClick={() => handleOpenModal(index)}> 
                        <img src={slide.img} alt='' />
                    </div>
                })}
            </div>
        </div>
    )
}

export default Gallery 