import Gallery from "./galleryImages";

const Photos = () => {

    const images = [
        { img: `${require("../../images/sliderimg1.jpg")}` },
        { img: `${require("../../images/sliderimg0.jpg")}` },
        { img: `${require("../../images/sliderimg3.jpg")}` },
        { img: `${require("../../images/sliderimg14.jpg")}` },
        { img: `${require("../../images/sliderimg2.jpg")}` },
        { img: `${require("../../images/sliderimg6.png")}` },
        { img: `${require("../../images/sliderimg7.jpg")}` },
        { img: `${require("../../images/sliderimg12.jpg")}` },
        { img: `${require("../../images/sliderimg8.jpg")}` },
        { img: `${require("../../images/sliderimg10.jpg")}` },
        { img: `${require("../../images/sliderimg11.jpg")}` },
        { img: `${require("../../images/sliderimg13.jpg")}` },
        { img: `${require("../../images/sliderimg14.jpg")}` },
        { img: `${require("../../images/sliderimg15.jpg")}` },
        { img: `${require("../../images/sliderimg16.jpg")}` },
        { img: `${require("../../images/sliderimg17.jpg")}` },
        { img: `${require("../../images/sliderimg18.jpg")}` },
        { img: `${require("../../images/sliderimg19.jpg")}` },
        { img: `${require("../../images/sliderimg20.jpg")}` },
        { img: `${require("../../images/sliderimg21.jpg")}` },
      ];

    return <div>
        <Gallery images={images} />
    </div>
}

export default Photos